/* eslint-disable no-process-env */
/* eslint-disable camelcase */
import React, { useContext, useState, useEffect } from 'react'
import styles from './Footer.module.css'
import content from './Footer.content'
import { PatientContext } from '../../context/PatientContext'
import { WHATSAPP_COUNTRY_CO, WHATSAPP_COUNTRY_MX } from '../../utils/constants'

/**
 * Footer component
 * @returns {Void} Footer component
 */
const Footer = () => {
  const { patient } = useContext(PatientContext)
  const { Country_Ops } = patient

  const [footerState, setFooterState] = useState({
    whatsapp: '',
    whatsappShow: '',
    facebook: '',
    instagram: '',
    youtube: ''
  })

  useEffect(() => {
    let newState = {}
    switch (Country_Ops) {
    case 'Colombia':
    case 'colombia':
      newState = {
        whatsapp: encodeURIComponent(`${WHATSAPP_COUNTRY_CO}${process.env.REACT_APP_WHATS_CO}`),
        whatsappShow: process.env.REACT_APP_WHATS_CO,
        facebook: content.facebook.replace('[domain]', '.co'),
        instagram: content.instagram.replace('[domain]', '.co'),
        youtube: content.youtube
      }
      break

    default:
      newState = {
        whatsapp: encodeURIComponent(`${WHATSAPP_COUNTRY_MX}${process.env.REACT_APP_WHATS_MX}`),
        whatsappShow: process.env.REACT_APP_WHATS_MX,
        facebook: content.facebook.replace('[domain]', ''),
        instagram: content.instagram.replace('[domain]', ''),
        youtube: content.youtube
      }
      break
    }
    setFooterState(newState)
  }, [Country_Ops])

  const contactClasses = [styles.Icons, styles.WhatsContainer]
  const whatsappClasses = [styles.Icons, styles.WhatsappIcon]

  return (
    <div className={styles.FooterContainer}>
      <div className={styles.Contact}>
        <a
          href={content.WHATSAPP_URL.replace('{moonsNum}', footerState.whatsapp)}
          target="_blank"
          rel="noopener noreferrer"
          className={contactClasses.join(' ')}
        >
          <div
            className={whatsappClasses.join(' ')}
          />
          {footerState.whatsappShow}
        </a>
      </div>
      <div className={styles.SocialNetworks}>
        <a
          href={footerState.facebook}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.Icons}
        >
          <i className={styles.FacebookIcon} />
        </a>
        <a
          href={footerState.instagram}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.Icons}
        >
          <i className={styles.InstagramIcon} />
        </a>
        <a
          href={footerState.youtube}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.Icons}
        >
          <i className={styles.YoutubeIcon} />
        </a>
      </div>
    </div>
  )
}

export default Footer
