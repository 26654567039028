/* eslint-disable multiline-ternary */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import moment from 'moment'
import styles from './CardCenter.module.css'
import globalStyles from '../../index.module.css'
import content from './CardCenter.content'
import Utils from '../../utils/utils'
import defaultImg from '../../assets/png/center-default.png'
import flameImg from '../../assets/png/flame.png'
import { ZERO } from '../../utils/constants'

/**
 * CardCenter component
 * @param {Object} center object with center data
 * @param {Number} peopleBooked is first center
 * @param {Boolean} noShadow remove box shadow
 * @param {Function} handleSelect handles center selection
 * @returns {Void} CardCenter component
 */
const CardCenter = ({
  center,
  date,
  peopleBooked,
  noShadow,
  handleSelect
}) => {
  const weekBookingsClasses = [globalStyles.Row, styles.WeekBookings]
  const bookPriceClasse = [styles.PriceContainer]

  const newDate = moment(date).format('dddd DD MMMM')
  const time = moment(date).format('HH:mm')
  const chosenPeople = peopleBooked || sessionStorage.getItem(`${center.Center_Name}`)
  const ctaBtnRef = React.createRef()

  return (
    <div className={noShadow ? styles.CardNoShadow : styles.CenterCard}>
      <div className={noShadow ? styles.LeftCardNoShadow : styles.LeftCard}>
        {/* Location icon */}
        <div className={styles.LeftImg}>
          <img
            src={center.Center_Icon ? center.Center_Icon : defaultImg}
            alt={`location-${center.Center_Name}`}
            className={
              center.Center_Icon ? styles.TowerImg : styles.DefaultImg
            }
          />
        </div>
        {/* Address info */}
        <div className={[globalStyles.DoubleColumn, styles.LeftText].join(' ')}>
          <div className={styles.LocationTitle}>
            {content.appointment.replace('[location]', center.Center_Name)}
          </div>
          <div className={styles.Street}>
            {content.address.replace('[street]', center.Street).replace('[num]', center.Number)}
          </div>
          <div className={styles.City}>
            {center.Neighborhood}
          </div>
          {date ? (
            <div className={styles.OfficeDate}>
              <p>{newDate}</p>
              <p>{`${time} hrs`}</p>
            </div>
          ) : (
            <div className={styles.OfficeHrs}>
              {Utils.parseTimeTable(center.Timetable)}
            </div>
          )}
          <div className={weekBookingsClasses.join(' ')}>
            <img
              src={flameImg}
              alt="fire-icon"
              className={styles.FlameIcon}
            />
            <span className={styles.WeekBkgsText}>
              {content.weekBookings.replace('[people]', chosenPeople)}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.RightCard}>
        {/* Price detail */}
        <div className={bookPriceClasse.join(' ')}>
          <span className={styles.Price}>
            {content.price.replace('[price]', Utils.getStringCurrency(center.List_Price || ZERO, center.Currency))
              .replace('[currency]', center.Currency)
            }
          </span>
          {/* <span className={styles.DiscountPrice}>
            {content.from}
          </span> */}
          <span className={styles.DiscountPrice}>
            {content.discount.replace('[price]', Utils.getStringCurrency(center.Appointment_Cost, center.Currency))
              .replace('[currency]', center.Currency)
            }
          </span>
          {
            !noShadow && (
              <button
                type="button"
                className={styles.SelectBtn}
                ref={ctaBtnRef}
                onClick={() => {
                  ctaBtnRef.current.blur()
                  handleSelect(center)
                }}
              >
                {content.select}
              </button>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default CardCenter
