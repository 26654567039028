/* eslint-disable camelcase */
import React, { useContext, useCallback, useState } from 'react'
import moment from 'moment'
import styles from './Success.module.css'
import content from './Success.content'
import { BookingContext } from '../../context/BookingContext'
import cosmonautImg from '../../assets/png/cosmonaut.png'
import checkIcon from '../../assets/svg/check-icon.svg'
import calendar from '../../assets/svg/calendar.svg'
import mapa from '../../assets/svg/mapa.svg'
import Summary from './Summary/Summary'
import SendPhotos from './SendPhotos/SendPhotos'

/**
 * Success component
 * @returns {Void} Success component
 */
const Success = () => {
  const { current, appointment } = useContext(BookingContext)
  const {
    Notes, Zone, Street, Apt, Neighborhood, Number, State, Country, Zip,
    Map_Image, Map_URL
  } = current

  const [showMap, setShowMap] = useState(true)

  const showImageMap = Map_Image && showMap

  /**
   * Build Calendar Link
   * @returns {Void} Success component
   */
  const buildCalendarLink = useCallback(() => {
    const dateTime = appointment.datetime
    const timeStart = moment(dateTime).format('YYYYMMDDTHHmmss')
    const timeEnd = moment(dateTime)
      .add(appointment.duration, 'minutes')
      .format('YYYYMMDDTHHmmss')

    const text = appointment.type
    const location = `${Zone} - ${Street}, ${encodeURIComponent(Number)}, 
    ${Apt === '' ? '' : `${encodeURIComponent(Apt)},`} ${Country === 'México' ? `${Neighborhood}, ${Zip},` : ''} ${State}`

    const details = `${location}%0A%0AVer/modificar cita:%0A${appointment.confirmationPage}`

    const link = `https://calendar.google.com/calendar/r/eventedit?dates=${timeStart}/${timeEnd}&text=${text}&location=${location}&details=${details}&sf=true`
    return link
  }, [
    appointment.confirmationPage,
    appointment.datetime,
    appointment.duration,
    appointment.type,
    Street,
    Neighborhood,
    Country,
    Zip,
    State,
    Zone,
    Number,
    Apt
  ])

  const linkCalendar = buildCalendarLink()

  return (
    <div className={styles.MainContainer}>
      {/* thanks cosmonaut */}
      <div className={styles.ThanksContainer}>
        <img
          src={cosmonautImg}
          alt="cosmonaut"
          className={styles.Cosmonaut}
        />
        <div className={styles.ThankyouContainer}>
          <div className={styles.Thankyou}>
            <img
              src={checkIcon}
              alt="check-positive"
              className={styles.CheckIcon}
            />
            <div className={styles.ThanksText}>
              {content.thanks}
            </div>
          </div>
        </div>
      </div>
      <div className={[styles.Section, Notes ? '' : styles.Center].join(' ')}>
        {/* appointment summary */}
        <div className={styles.ResumeLink}>
          <Summary />
          <div className={styles.ContainerLinkCalendar}>
            <img
              className={styles.ImageCalendar}
              alt="calendar"
              src={calendar}
            />
            <a
              href={linkCalendar}
              className={styles.LinkCalendar}
              rel="noopener noreferrer"
              target="_blank"
            >
              {content.addCalendar}
            </a>
          </div>
        </div>
        {/* recommendations */}
        {
          Notes && (
            <div className={styles.Recommendation}>
              <div className={styles.ContainerRec}>
                <p className={styles.TitleRecommendation}>{content.recommendations}</p>
                <p className={styles.ItemRec}>
                  {Notes}
                </p>
              </div>
            </div>
          )
        }
      </div>
      {/* map */}
      <div className={styles.MapImagesContainer}>
        {
          showImageMap && (
            <div
              role="button"
              tabIndex={0}
              onClick={() => window.open(Map_URL)}
              className={styles.MapCont}
            >
              <img
                alt="map-branch"
                src={Map_Image}
                className={styles.MapImage}
                onError={() => setShowMap(false)}
              />
            </div>
          )
        }
        {
          Map_URL && (
            <div
              role="button"
              tabIndex={0}
              className={styles.SeeMaps}
              onClick={() => window.open(Map_URL)}
            >
              <img
                alt="map"
                src={mapa}
                className={styles.IconMap}
              />
              <p>{content.seeMaps}</p>
            </div>
          )
        }
      </div>
      <SendPhotos />
    </div>
  )
}

export default Success
