import { createRef } from 'react'

/**
 * getStringCurrency transforms number currency to readable number currency string
 * @param {NUmber} amount of money
 * @param {String} currency  locale currency
 * @returns {String} currency amount in string
 */
const getStringCurrency = (amount, currency) => {
  const zero = 0
  switch (currency) {
  case 'COP':
    return Number(amount.toFixed(zero)).toLocaleString('es-CO', { style: 'currency', currency })

  default:
    return Number(amount.toFixed(zero)).toLocaleString('es-MX', { style: 'currency', currency })
  }
}

/**
 * DynamicRef Function
 * @param {String} total .
 * @return {void}
 */
const dynamicRef = (total) => {
  const refs = []
  for (let initial = 0; initial < total; initial++) {
    refs[initial] = createRef()
  }
  return refs
}

/**
 * Get Style Item By Property
 * @param {String} node .
 * @param {String} property .
 * @return {void}
 */
const getStyleItemByProperty = (node, property) => {
  const nodeStyle = window.getComputedStyle(node)
  const styles = parseInt(nodeStyle.getPropertyValue(property), 10)
  return styles
}

/**
 * Parse timetable from SmileCenter
 * @param {Object} timetable .
 * @returns {String} .
 */
const parseTimeTable = (timetable) => {
  let timeResult = ''
  if (timetable.weekdays) {
    timeResult += `L-V ${timetable.weekdays.join(' ')}`
  }
  if (timetable.saturday) {
    timeResult += ` / S ${timetable.saturday.join(' ')}`
  }
  if (timetable.sunday) {
    timeResult += ` / D ${timetable.sunday.join(' ')}`
  }
  return timeResult
}

export default {
  getStringCurrency,
  dynamicRef,
  getStyleItemByProperty,
  parseTimeTable
}
