import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Booking from './components/Booking/Booking'
import BookingProvider from './context/BookingContext'
import PatientProvider from './context/PatientContext'
import CheckoutWrapper from './components/Checkout/Checkout'
/**
 * App component
 * @returns {Void} App component
 */
const App = () => (
  <BookingProvider>
    <PatientProvider>
      <BrowserRouter>
        <Switch>
          <Route
            path="/checkout"
            component={() => <CheckoutWrapper country="México" />}
          />
          <Route
            path="/:id"
            component={() => <Booking />}
          />
        </Switch>
      </BrowserRouter>
    </PatientProvider>
  </BookingProvider>
)

export default App
