import React from 'react'
import moon from '../../assets/png/lunafooter.png'
import ring from '../../assets/png/path.png'
import styles from './LoaderFullScreen.module.css'

/**
 * Loader Full Screen Component
 * @returns {React.Component} LoaderFullScreen .
 */
const LoaderFullScreen = ({ message }) => {
  window.scrollTo(0, 0)
  return (
    <div className={styles.Container}>
      <div className={styles.SealRing}>
        <img
          src={ring}
          alt="Animacion"
        />
        <div className={styles.SealMoon}>
          <img
            src={moon}
            alt="Holograma Moons"
          />
        </div>
      </div>
      <div className={styles.ContainerMessage}>{message}</div>
    </div>
  )
}

export default LoaderFullScreen
