/* eslint-disable no-magic-numbers */
import React from 'react'
import 'moment/locale/es'
import styles from './Slider.module.css'
import start from '../../../assets/svg/star.svg'
import okBlue from '../../../assets/svg/tick.svg'

/**
 * Slider Component
 * @return {void}
 */
const Slider = ({ item }) => {
  const name = `${item.name}, ${item.age}`
  const starts = [1,
    2,
    3,
    4,
    5]
  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <img
          alt="slide"
          src={item.image}
          className={styles.Images}
        />
        <div className={styles.ContainerName}>
          <p className={styles.Name}>{name}</p>
          <div className={styles.Starts}>
            {starts.map(st => (
              <img
                key={st}
                alt="start"
                src={start}
                className={styles.ItemStart}
              />
            ))}
          </div>
        </div>
        <img
          alt="ok"
          src={okBlue}
          className={styles.OkBlue}
        />
      </div>
      <div className={styles.ContainerBody}>
        <div className={styles.ContainerBody}>
          <p className={styles.Title}>{item.title}</p>
          <p className={styles.Description}>{item.description}</p>
        </div>
      </div>
    </div>
  )
}

export default Slider
