/* eslint-disable camelcase */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-literals */
import React, {
  useContext, useEffect, useState, useCallback
} from 'react'
import styles from './AppointmentCenters.module.css'
import content from './AppointmentCenters.content'
import { ONE, DATES_STEP } from '../../utils/constants'
import { BookingContext } from '../../context/BookingContext'
import { PatientContext } from '../../context/PatientContext'
import LoaderSection from '../LoaderSection/LoaderSection'
import Middleware from '../../Api/Middleware'
import CardCenter from '../CardCenter/CardCenter'

/**
 * get rnadom number in range
 * @param {Number} minRange number
 * @param {Number} maxRange number
 * @returns {Number} range
 */
const getRandomNumber = (minRange, maxRange) => {
  const min = Math.ceil(minRange)
  const max = Math.floor(maxRange)
  return Math.floor(Math.random() * (max - min + content.one)) + min
}

/**
 * AppointmentCenters component
 * list of centers with location and price
 * @param {Function} nextStep handles next step
 * @returns {Void} AppointmentCenters component
 */
const AppointmentCenters = ({ nextStep }) => {
  const [error, setError] = useState(false)
  const { centers, changeCenters, changeCurrentCenter } = useContext(BookingContext)
  const { patient } = useContext(PatientContext)
  const { Country_Ops } = patient

  useEffect(() => {
    const requestBody = {
      table: 'SmileCenter',
      columns: {
        Region: Country_Ops === 'México' ? 'CDMX' : 'Medellin',
        Active: true
      }
    }
    Middleware.requestList(requestBody, changeCenters, setError)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * dfsdfsd
   * @param {*} idCentro sdf
   * @returns {Void} .
   */
  const getPoeple = useCallback((idCentro) => {
    let peopleBooked = content.fifty
    const booked = sessionStorage.getItem(`${idCentro}`)
    if (booked) {
      peopleBooked = booked
    } else {
      peopleBooked = getRandomNumber(content.thirty, content.fifty)
      sessionStorage.setItem(`${idCentro}`, peopleBooked)
    }
    return peopleBooked
  }, [])

  /**
   * Handles center selection
   */
  const selectCenter = useCallback((center) => {
    changeCurrentCenter(center)
    nextStep(DATES_STEP)
  }, [changeCurrentCenter, nextStep])

  const centerCardClasses = [styles.CenterCard]

  if (error) {
    return (
      <div className={styles.MainContainer}>
        <div>{content.noResults}</div>
      </div>
    )
  }

  if (centers.length <= content.zero) {
    return (
      <div className={styles.MainContainer}>
        <LoaderSection className={styles.Loader} />
      </div>
    )
  }

  return (
    <div className={styles.MainContainer}>
      <div className={styles.InitialMsg}>
        {content.title}
      </div>
      <div className={styles.CenterListContainer}>
        {
          centers.map((center, index) => {
            centerCardClasses.push(index > ONE ? styles.PairCard : '')
            return (
              <div
                key={center.Appointment_Type_Id}
                className={centerCardClasses.join(' ')}
              >
                <CardCenter
                  center={center}
                  peopleBooked={() => getPoeple(center.Center_Name)}
                  handleSelect={selectCenter}
                />
              </div>
            )
          })
        }
      </div>
      <div className={styles.FooterNote}>
        {content.footer}
      </div>
    </div>
  )
}

export default AppointmentCenters
