const cardContent = {
  appointment: 'Cita [location]',
  address: '[street] [num],',
  weekBookings: '[people] personas agendaron esta semana',
  price: '[price] [currency]',
  discount: '[price] [currency]',
  from: 'desde',
  select: 'SELECCIONAR'
}

export default cardContent
