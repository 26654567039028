/* eslint-disable react/jsx-no-literals */
/* eslint-disable multiline-ternary */
/* eslint-disable camelcase */
import React, { useContext } from 'react'
import moment from 'moment'
import 'moment/locale/es'
import styles from './Summary.module.css'
import clock from '../../../assets/svg/clock.svg'
import implant from '../../../assets/svg/implant.svg'
import cosmonaut from '../../../assets/svg/cosmonautBlue.svg'
import { BookingContext } from '../../../context/BookingContext'
import { PatientContext } from '../../../context/PatientContext'

/**
 * Summary component
 * @returns {Void} Summary component
 */
const Summary = () => {
  const { current, dateSelected } = useContext(BookingContext)
  const {
    Center_Name, Street, Number, Neighborhood, Center_Icon
  } = current
  const { patient } = useContext(PatientContext)
  const { Patient_Name } = patient

  const address = `${Street} ${Number}, ${Neighborhood}`
  const date = moment(dateSelected).format('dddd DD [de] MMMM')
  const time = moment(dateSelected).format('HH:mm')
  return (
    <div className={styles.CenterContainer}>
      <div className={styles.Item}>
        <img
          className={styles.Icon}
          alt="cosmonaut-icon"
          src={cosmonaut}
        />
        <div className={styles.InfoDate}>
          <div className={styles.Name}>
            {Patient_Name}
          </div>
        </div>
      </div>
      <div className={styles.ItemSecond}>
        {
          Center_Icon ? (
            <img
              className={[styles.Icon, styles.BlueIcon].join(' ')}
              alt="icon"
              src={Center_Icon}
            />
          ) : (
            <img
              className={styles.Icon}
              alt="icon"
              src={implant}
            />
          )
        }
        <div className={styles.AddressContainer}>
          <div className={styles.Center}>
            {Center_Name}
          </div>
          <div className={styles.Address}>
            {address}
          </div>
        </div>
      </div>
      <div className={styles.ItemSecond}>
        <img
          className={styles.Icon}
          alt="icon"
          src={clock}
        />
        <div className={styles.InfoDate}>
          <div className={styles.Dates}>
            {date}
          </div>
          <div className={styles.Address}>
            {`${time} hrs`}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Summary
