const sendPhotos = {
  title: 'Toma 3 fotografías de tu sonrisa y ahorra 15 minutos en tu cita',
  firstStep: 'Envíanos 3 fotografias, te toma 5 min',
  secondStep: 'Conoce tu diagnóstico y recibe tu devolución en caso de nos ser candidato',
  thirdStep: 'Ahorra 15 min en tu cita si eres candidato',
  sendBtn: 'ENVIAR FOTOS',
  photosUrl: 'https://misfotos.mymoons.[domain]/patient/[objectId]'
}

export default sendPhotos
