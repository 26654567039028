/* eslint-disable global-require */
const data = [
  {
    id: 0,
    name: 'Armando',
    age: '27',
    title: 'Mejores que los brackets',
    description: 'Tuve brackets antes y ni loco los volvería a usar. Los moons son mucho más cómodos Ojalá los hubiera conocido hace 10 años!!!!',
    image: require('../assets/jpg/testimony1.jpg')
  },
  {
    id: 1,
    name: 'Daniela',
    age: '29',
    title: 'Son invisibles!',
    description: 'La gente en verdad no se da cuenta de que estoy usando los moons! Por fin pude arreglar mis dientes sin aparatos horribles :D',
    image: require('../assets/jpg/testimony2.jpg')
  },
  {
    id: 2,
    name: 'Gaby',
    age: '34',
    title: 'Me encantan!',
    description: 'No estaba convencida de que funcionaran. Apenas voy a la mitad del tratamiento y mis dientes ya están mucho más derechos. ',
    image: require('../assets/jpg/testimony3.jpg')
  }
]

export default data
