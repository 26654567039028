/* eslint-disable no-process-env */
import { callApiDirectly } from './Request'

const apiCRM = {

  /**
   * Save Appointment Data in CRM
   * @param {String} customerId .
   * @param {Object} appointment .
   * @param {String} appointment.Smile_Center .
   * @param {String} appointment.Appointment_Date .
   * @returns {void} .
   */
  saveAppointment(customerId, appointment) {
    return callApiDirectly(process.env.REACT_APP_SAVE_APPOINTMENT, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        customerId,
        appointment
      }),
    })
  },
}

export default apiCRM
