/* eslint-disable react/display-name */
import React, { useState } from 'react'

export const BookingContext = React.createContext({
  centers: [],
  changeCenters: (centers) => {},
  homekit: {},
  changeHomekit: (homekitdata) => {},
  current: {},
  changeCurrentCenter: (center) => {}
})

export default props => {
  const { children } = props
  const [centersList, setCentersList] = useState([])
  const [dateSelected, setDate] = useState([])
  const [homekitData, setHomekitData] = useState({})
  const [currentCenter, setCurrentCenter] = useState({})
  const [appointment, setAppointment] = useState({})
  return (
    <BookingContext.Provider
      value={{
        centers: centersList,
        changeCenters: setCentersList,
        homekit: homekitData,
        changeHomekit: setHomekitData,
        current: currentCenter,
        changeCurrentCenter: setCurrentCenter,
        dateSelected,
        setDate,
        appointment,
        setAppointment
      }}
    >
      {children}
    </BookingContext.Provider>
  )
}
