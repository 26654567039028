import React from 'react'
import styles from './AppointmentData.module.css'
import globalStyles from '../../index.module.css'
import content from './AppointmentData.content'
import drImg from '../../assets/png/doctor.png'
import smileImg from '../../assets/png/smile.png'
import apolloImg from '../../assets/png/apollo.png'

/**
 * AppointmentData component
 * @returns {Void} AppointmentData component
 */
const AppointmentData = () => (
  <div className={styles.MainContainer}>
    <div className={styles.Title}>
      {content.title}
    </div>
    <div className={styles.Row}>
      <div className={styles.FirstStep}>
        <div className={globalStyles.Column}>
          <img
            src={drImg}
            alt="doctor-check"
            className={styles.DrImage}
          />
        </div>
        <div className={globalStyles.DoubleColumn}>
          <div className={styles.FirstText}>
            {content.first}
          </div>
        </div>
      </div>
      <div className={styles.SecondStep}>
        <div className={globalStyles.Column}>
          <img
            src={smileImg}
            alt="smile-step"
            className={styles.SmileImage}
          />
        </div>
        <div className={globalStyles.DoubleColumn}>
          <div className={styles.SecondText}>
            {content.second}
          </div>
        </div>
      </div>
      <div className={styles.ThirdStep}>
        <div className={globalStyles.Column}>
          <img
            src={apolloImg}
            alt="apollo-step"
            className={styles.ApolloImage}
          />
        </div>
        <div className={globalStyles.DoubleColumn}>
          <div className={styles.ThirdText}>
            {content.third}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default AppointmentData
