/* eslint-disable camelcase */
import React, { useContext } from 'react'
import styles from './SendPhotos.module.css'
import content from './SendPhotos.content'
import cameraImg from '../../../assets/png/camera.png'
import refundImg from '../../../assets/png/refund.png'
import saveTimeImg from '../../../assets/png/save-time.png'
import { PatientContext } from '../../../context/PatientContext'

/**
 * SendPhotos component
 * @returns {Void} SendPhotos component
 */
const SendPhotos = () => {
  const { patient } = useContext(PatientContext)
  const { Country_Ops, CustomerId } = patient
  const ctaBtnRef = React.createRef()

  let sendPhotosUrl = content.photosUrl
  switch (Country_Ops) {
  case 'Colombia':
  case 'colombia':
    sendPhotosUrl = sendPhotosUrl.replace('[domain]', 'co').replace('[objectId]', CustomerId)
    break

  default:
    sendPhotosUrl = sendPhotosUrl.replace('[domain]', 'mx').replace('[objectId]', CustomerId)
    break
  }

  return (
    <div className={styles.MainContainer}>
      <div className={styles.StepsContainer}>
        <div className={styles.Title}>
          {content.title}
        </div>
        <div className={styles.Row}>
          <div className={styles.ImgContainer}>
            <img
              src={cameraImg}
              alt="camera-desc"
              className={styles.CameraImage}
            />
          </div>
          <div className={styles.TextContainer}>
            {content.firstStep}
          </div>
        </div>
        <div className={styles.Row}>
          <div className={styles.ImgContainer}>
            <img
              src={refundImg}
              alt="refund-desc"
              className={styles.RefundImage}
            />
          </div>
          <div className={styles.TextContainer}>
            {content.secondStep}
          </div>
        </div>
        <div className={styles.Row}>
          <div className={styles.ImgContainer}>
            <img
              src={saveTimeImg}
              alt="save-time-desc"
              className={styles.SaveTimeImage}
            />
          </div>
          <div className={styles.TextContainer}>
            {content.thirdStep}
          </div>
        </div>
        <div className={styles.ButtonContainer}>
          <button
            type="button"
            className={styles.SendButton}
            ref={ctaBtnRef}
            onClick={() => {
              window.open(sendPhotosUrl)
              ctaBtnRef.current.blur()
            }}
          >
            {content.sendBtn}
          </button>
        </div>
      </div>
    </div>
  )
}

export default SendPhotos
