const content = {
  appointmentTitle: 'Tu cita está apartada por 10:00 minutos',
  appointmentText: 'Después se liberará a otros pacientes',
  paidText: 'Confirmala pagando: ',
  priceTextMX: '${}.00 MXN',
  priceBeforeMX: '${}.00 MXN',
  priceTextCO: '${}.00 COP',
  priceBeforeCO: '${}.00 COP',
  ivaCopy: '*nuestros precios incluyen IVA',
  dentalReviewText: 'Revisión dental',
  diagnosticText: 'Diagnóstico',
  scanText: 'Escaneo 3D',
  payButtonText: 'PAGAR CITA',
  errorTextTitle: 'Lo sentimos tu pago fue rechazado',
  errorText: 'Vuelve a intentarlo o trata con otra tarjeta',
  contactTitle: '¿Tienes problemas para pagar?',
  whastappTitle: 'CONTACTANOS'
}

export default content
