import React from 'react'
import styles from './News.module.css'
import globalStyles from '../../index.module.css'
import news1Img from '../../assets/png/news1.png'
import news2Img from '../../assets/png/news2.png'
import news3Img from '../../assets/png/news3.png'

/**
 * News component
 * @returns {Void} News component
 */
const News = () => (
  <div className={styles.MainContainer}>
    <div className={globalStyles.Row}>
      <div className={globalStyles.Column}>
        <img
          src={news1Img}
          alt="news-one"
          className={styles.News1}
        />
      </div>
      <div className={globalStyles.Column}>
        <img
          src={news2Img}
          alt="news-two"
          className={styles.News2}
        />
      </div>
      <div className={globalStyles.Column}>
        <img
          src={news3Img}
          alt="news-three"
          className={styles.News3}
        />
      </div>
    </div>
  </div>
)

export default News
