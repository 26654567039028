/* eslint-disable no-process-env */
/* eslint-disable camelcase */
/* eslint-disable max-statements */
import React, {
  useState, useContext, useEffect, useCallback
} from 'react'
import styles from './HomeKit.module.css'
import globalStyles from '../../index.module.css'
import content from './HomeKit.content'
import homeKitImg from '../../assets/png/homekit.png'
import Utils from '../../utils/utils'
import arrowUp from '../../assets/png/arrow-up.png'
import arrowDown from '../../assets/png/arrow-down.png'
import HomeKitDesktop from './HomeKitDesktop/HomeKitDesktop'
import { BookingContext } from '../../context/BookingContext'
import { PatientContext } from '../../context/PatientContext'
import LoaderSection from '../LoaderSection/LoaderSection'
import Middleware from '../../Api/Middleware'

/**
 * HomeKit component
 * shows home kit description and button to checkout
 * @returns {Void} HomeKit component
 */
const HomeKit = () => {
  const [error, setError] = useState(false)
  const { patient } = useContext(PatientContext)
  const { Country_Ops, PaymentId } = patient
  const { homekit, changeHomekit } = useContext(BookingContext)
  const { finalPrice, currency } = homekit

  useEffect(() => {
    const requestBody = {
      table: 'Product',
      columns: {
        keyName: Country_Ops === 'México' ? process.env.REACT_APP_PRODUCT_KEY_HOME_KIT_MX : process.env.REACT_APP_PRODUCT_KEY_HOME_KIT_CO
      }
    }
    Middleware.sendRequest(requestBody, changeHomekit, setError, 'Product')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [showMore, setShowMore] = useState(false)
  const showBtnRef = React.createRef()
  const buyBtnRef = React.createRef()

  /**
   * checkout handles home kit checkout
   */
  const checkout = useCallback(() => {
    buyBtnRef.current.blur()
    let checkoutUrl = content.checkout
    switch (Country_Ops) {
    case 'Colombia':
    case 'colombia':
      checkoutUrl = checkoutUrl.replace('[domain]', 'co')
      break

    default:
      checkoutUrl = checkoutUrl.replace('[domain]', 'mx')
      break
    }
    checkoutUrl = checkoutUrl.replace('[paymentId]', PaymentId)
    window.open(checkoutUrl)
  }, [
    Country_Ops,
    PaymentId,
    buyBtnRef
  ])

  if (error) {
    return (
      <div className={styles.MainContainer}>
        <div>{content.noResults}</div>
      </div>
    )
  }

  if (Object.keys(homekit).length <= content.zero) {
    return (
      <div className={styles.MainContainer}>
        <LoaderSection className={styles.Loader} />
      </div>
    )
  }

  const contentClasses = [globalStyles.Column, styles.KitContent]

  return (
    <div className={styles.MainContainer}>
      <div className={styles.MobileContent}>
        <div className={styles.Heading}>
          <div className={styles.Hoa}>
            <img
              src={homeKitImg}
              alt="homekit-model"
              className={styles.HomeKitImg}
            />
          </div>
          <div className={contentClasses.join(' ')}>
            <div className={styles.InitialDesc}>
              {content.message}
            </div>
            <div className={styles.BuyContainer}>
              <div className={styles.Price}>
                {content.price.replace('[price]', Utils.getStringCurrency(finalPrice, currency)).replace('[currency]', currency)}
              </div>
              <button
                type="button"
                ref={buyBtnRef}
                className={styles.BuyBtn}
                onClick={checkout}
              >
                {content.buyBtn}
              </button>
            </div>
          </div>
        </div>
        <div
          role="button"
          tabIndex="0"
          ref={showBtnRef}
          className={styles.InfoBtn}
          onClick={() => {
            setShowMore(!showMore)
            showBtnRef.current.blur()
          }}
        >
          {showMore ? content.showLess : content.showMore}
          <img
            src={showMore ? arrowUp : arrowDown}
            alt="up-down-icon"
            className={styles.ArrowIcon}
          />
        </div>
        {
          showMore && (
            <div className={styles.KitDescription}>
              {content.kitDesc}
            </div>
          )
        }
      </div>
      <HomeKitDesktop
        price={Utils.getStringCurrency(finalPrice, currency)}
        currency={currency}
        handleCheckout={checkout}
      />
    </div>
  )
}

export default HomeKit
