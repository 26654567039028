/* eslint-disable no-tabs */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-literals */
import React from 'react'
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import styles from './Checkout.module.css'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
}

/**
 * @returns {React.Component} -.
 */
const CheckoutForm = ({ errorCard, errorCvc, errorDate }) => {
  let errorCardComponent = null
  let errorCvcComponent = null
  let errorDateComponent = null
  if (errorCard) {
    errorCardComponent = (
      <span
        style={{
          color: 'red',
          fontSize: '12px'
        }}
      >
        Verifica tu tarjeta
      </span>
    )
  }

  if (errorCvc) {
    errorCvcComponent = (
      <span
        style={{
          color: 'red',
          fontSize: '12px'
        }}
      >
        Verifica tu Código
      </span>
    )
  }

  if (errorDate) {
    errorDateComponent = (
      <span
        style={{
          color: 'red',
          fontSize: '12px'
        }}
      >
        Verifica tu fecha
      </span>
    )
  }
  return (
    <form>
      <div className={styles.Row}>
        <div className={styles.Col}>
          <label htmlFor="cardNumber">Número de tarjeta</label>
          <CardNumberElement
            className={styles.CardInput}
            id="cardNumber"
            options={ELEMENT_OPTIONS}
          />
          {errorCardComponent}
        </div>
      </div>
      <div className={styles.Row}>
        <div className={styles.Col2Left}>
          <label htmlFor="expiry">Caducidad</label>
          <CardExpiryElement
            className={styles.CardInput}
            id="expiry"
            options={ELEMENT_OPTIONS}
          />
          {errorDateComponent}
        </div>
        <div className={styles.Col2Right}>
          <label htmlFor="cvc">CVV</label>
          <CardCvcElement
            className={styles.CardInput}
            id="cvc"
            options={ELEMENT_OPTIONS}
          />
          {errorCvcComponent}
        </div>
      </div>
    </form>
  )
}

export default CheckoutForm
