import React from 'react'
import styles from './HomeKitDesktop.module.css'
import globalStyles from '../../../index.module.css'
import content from '../HomeKit.content'
import homeKitImg from '../../../assets/png/homekit.png'

/**
 * HomeKitDesktop component
 * view for desktop
 * @param {String} price Home kit price
 * @param {String} currency Home kit price
 * @param {Function} handleCheckout handles checkout
 * @returns {Void} HomeKitDesktop component
 */
const HomeKitDesktop = ({ price, currency, handleCheckout }) => {
  const buyBtnRef = React.createRef()
  const mainClasses = [styles.MainContent, globalStyles.Row]

  return (
    <div className={mainClasses.join(' ')}>
      <img
        src={homeKitImg}
        alt="homekit-model"
        className={styles.HomeKitImg}
      />
      {/* <div className={globalStyles.Column}>
      </div> */}
      <div className={globalStyles.DoubleColumn}>
        <div className={styles.InitialDesc}>
          {content.message}
        </div>
        <div className={styles.KitDescription}>
          {content.kitDesc}
        </div>
      </div>
      <div className={globalStyles.DoubleColumn}>
        <div className={styles.BuyContainer}>
          <div className={styles.Price}>
            {content.price.replace('[price]', price).replace('[currency]', currency)}
          </div>
          <button
            type="button"
            ref={buyBtnRef}
            className={styles.BuyBtn}
            onClick={() => {
              buyBtnRef.current.blur()
              handleCheckout()
            }}
          >
            {content.buyBtn}
          </button>
        </div>
      </div>
    </div>
  )
}

export default HomeKitDesktop
