import React from 'react'
import ChooseDates from '../ChooseDates/ChooseDates'
import styles from './Dates.module.css'

/**
 * Shell component.
 * @returns {void} .
 */
const Dates = ({
  currentBranch, name, nextStep, dateSelected, setDate
}) => (
  <div className={styles.Container}>
    <div className={styles.Wrapper}>
      <ChooseDates
        branch={currentBranch}
        name={name}
        nextStep={nextStep}
        setDate={setDate}
      />
    </div>
  </div>
)

export default Dates
