const centersContent = {
  title: 'Selecciona tu centro más cercano',
  appointment: 'Cita [location]',
  address: '[street] [num],',
  weekBookings: '[people] personas agendaron esta semana',
  price: '[price] [currency]',
  discount: '[price] [currency]',
  footer: '*el costo de tu cita se abonará a tu tratamiento',
  two: 2,
  zero: 0,
  one: 1,
  noResults: 'No hay resultados',
  fifty: 50,
  thirty: 30,
  five: 5,
}

export default centersContent
