const homeKitContent = {
  message: 'Nuestro home kit es la opción si no encontraste un centro cercano',
  showLess: 'menos información',
  showMore: 'más información',
  kitDesc: 'El kit de impresión tiene todo lo que necesitas para tomar una impresión de tu sonrisa, además tienes una video llamada para guiarte en el proceso.',
  buyBtn: 'COMPRAR',
  price: '[price] [currency]',
  zero: 0,
  noResults: 'No hay resultados',
  checkout: 'https://mymoons.[domain]/checkout/homekitLead/[paymentId]'
}

export default homeKitContent
