/* eslint-disable react/no-multi-comp */
import React from 'react'
import styles from './Header.module.css'
import logo from '../../assets/svg/logo.svg'

/**
 * Logo component.
 * @returns {void} .
 */
const Logo = () => (
  <div
    className={styles.LinkHome}
  >
    <img
      alt="logo"
      src={logo}
    />
  </div>
)

/**
 * Header component.
 * @returns {void} .
 */
const Header = () => (
  <header
    id="header"
    className={styles.MainContainer}
  >
    <div className={styles.SlideMenuContainer}>
      <div className={styles.Logo}>
        <Logo />
      </div>
    </div>
    <div className={styles.CenterHeader}>
      <div className={styles.MenuContent}>
        <div className={styles.LeftContent}>
          <div className={styles.Logo}>
            <Logo />
          </div>
        </div>
      </div>
    </div>
  </header>
)

export default Header
