import React, { useState } from 'react'

export const PatientContext = React.createContext({
  patient: {},
  changePatient: (patient) => {}
})

export default props => {
  const { children } = props
  const [customerInfo, setCustomerInfo] = useState({})

  /**
   * changeCustomer changes customer info in context
   * @param {Object} patientData Customer's data
   * @returns {Void} changes context
   */
  const changeCustomerInfo = (patientData) => {
    setCustomerInfo(patientData)
  }

  return (
    <PatientContext.Provider
      value={{ patient: customerInfo, changePatient: changeCustomerInfo }}
    >
      {children}
    </PatientContext.Provider>
  )
}
