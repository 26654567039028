/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, {
  useState, useEffect, useContext, useCallback
} from 'react'
import { withRouter } from 'react-router-dom'
import { PatientContext } from '../../context/PatientContext'
import { BookingContext } from '../../context/BookingContext'
import Middleware from '../../Api/Middleware'
import {
  INITIAL_STEP, DATES_STEP, ACCEPTS_STEP, CHECKOUT_STEP, SUCCESS_STEP
} from '../../utils/constants'
import styles from './Booking.module.css'
import content from './Booking.content'
import Welcome from '../Welcome/Welcome'
import AppointmentCenters from '../AppointmentCenters/AppointmentCenters'
import HomeKit from '../HomeKit/HomeKit'
import AppointmentData from '../AppointmentData/AppointmentData'
import NotFound from '../NotFound/NotFound'
import LoaderFullScreen from '../LoaderFullScreen/LoaderFullScreen'
import TestimonialsCarousel from '../Carousel'
import testimonials from '../../utils/testimonials'
import HealthMeasures from '../HealthMeasures/HealthMeasures'
import News from '../News/News'
import Dates from '../Dates/Dates'
import Shell from '../Shell/Shell'
import Accept from '../Accept/Accept'
import Success from '../Success/Success'
import CheckoutWrapper from '../Checkout/Checkout'
import Footer from '../Footer/Footer'

/**
 * Booking component main contianer
 * @param {Object} match router match
 * @returns {Void} Booking component
 */
const Booking = ({ match }) => {
  const [error, setError] = useState(false)
  const [isCTABtnSticky, setisCTASticky] = useState(true)
  const [steps, setSteps] = useState([
    {
      name: INITIAL_STEP,
      current: true
    },
    {
      name: DATES_STEP,
      current: false
    },
    {
      name: ACCEPTS_STEP,
      current: false
    },
    {
      name: CHECKOUT_STEP,
      current: false
    },
    {
      name: SUCCESS_STEP,
      current: false
    },
  ])

  const { patient, changePatient } = useContext(PatientContext)
  const {
    current,
    dateSelected,
    setDate
  } = useContext(BookingContext)
  // const ctaBtnRef = React.createRef()

  useEffect(() => {
    const params = match && match.params ? match.params : null
    const publicKey = params ? params.id : ''
    const requestBody = {
      table: 'Patient',
      columns: {
        PublicKey: publicKey
      }
    }
    Middleware.sendRequest(requestBody, changePatient, setError, 'Patient')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * changeSlide handles step change
   */
  const changeSlide = useCallback((stepName) => {
    const newSteps = steps.map(step => {
      if (step.name === stepName) {
        step.current = true
      } else {
        step.current = false
      }
      return step
    })
    setSteps(newSteps)
  }, [steps])

  /**
   * goToSelection handles choosing center and navigation for slecting date/time
   * @returns {Void} changes route
   */
  /*
   * const goToSelection = useCallback(() => {
   *   ctaBtnRef.current.blur()
   *   if (Object.keys(current).length <= content.zero) {
   *     alert('No has seleccionado un centro')
   *   } else {
   *     changeSlide(DATES_STEP)
   *   }
   * }, [
   *   changeSlide,
   *   ctaBtnRef,
   *   current
   * ])
   */

  if (error) {
    return <NotFound />
  }

  if (Object.keys(patient).length <= content.zero) {
    return <LoaderFullScreen />
  }

  /*
   * const mainClasses = [styles.MainContainer]
   * const actionBtnClasses = [styles.ActionBtnContact, styles.AnimationContainer]
   */
  const btnContainerClasses = [styles.BtnContainerContact]
  isCTABtnSticky && btnContainerClasses.push(styles.isSticky)

  const currentStep = steps.filter(step => step.current === true)[0]
  const name = patient && patient.Patient_Name
  const country = patient && patient.Country_Ops
  return (
    <Shell>
      <div className={styles.MainContainer}>
        {
          currentStep.name === INITIAL_STEP && (
            <>
              <div className={styles.FirstStepContainer}>
                <Welcome />
                <AppointmentCenters nextStep={changeSlide} />
                <HomeKit />
                <AppointmentData />
              </div>
              <TestimonialsCarousel info={testimonials} />
              <div className={styles.FirstStepContainer}>
                <HealthMeasures handleIsSticky={setisCTASticky} />
                {/* <div className={btnContainerClasses.join(' ')}>
                  <div
                    role="button"
                    tabIndex="0"
                    className={actionBtnClasses.join(' ')}
                    ref={ctaBtnRef}
                    onClick={goToSelection}
                  >
                    <div className={styles.BtnText}>
                      {content.bookBtn}
                    </div>
                  </div>
                </div> */}
                <News />
              </div>
            </>
          )
        }
        {currentStep.name === DATES_STEP && (
          <Dates
            currentBranch={current}
            name={name}
            nextStep={changeSlide}
            dateSelected={dateSelected}
            setDate={setDate}
          />
        )}
        {currentStep.name === ACCEPTS_STEP && (
          <Accept
            branch={current}
            name={name}
            nextStep={changeSlide}
            dateSelected={dateSelected}
            customerId={patient && patient.CustomerId}
            dealId={patient && patient.Deal_Id}
          />
        )}
        {currentStep.name === CHECKOUT_STEP && (
          <CheckoutWrapper
            country={country}
            nextStep={changeSlide}
          />
        )}
        {
          currentStep.name === SUCCESS_STEP && (
            <>
              <Success />
              <Footer />
            </>
          )
        }
      </div>
    </Shell>
  )
}

export default withRouter(Booking)
