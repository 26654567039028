import React from 'react'
import styles from './NotFound.module.css'
import content from './NotFound.content'
import logoWhite from '../../assets/svg/moons-white.svg'
import lunaMobile from '../../assets/svg/lunaMobile.svg'
import lunaDesktop from '../../assets/svg/lunaDesktop.svg'

/**
 * NotFound componente
 * @returns {Void} NotFound component
 */
const NotFound = () => (
  <div className={styles.ErrorContainer}>
    <div className={styles.Logo}>
      <img
        alt="logo-moons-white"
        src={logoWhite}
      />
    </div>
    <div className={styles.Error404}>{content.error}</div>
    <div className={styles.Subtitle}>{content.subtitle}</div>
    <div className={styles.NoFound}>{content.notFound}</div>
    <div className={styles.MoonsFooter}>
      <img
        className={styles.ImgMoonsFooterMobile}
        alt="moonFooter-notfound-img"
        src={lunaMobile}
      />
      <img
        className={styles.ImgMoonsFooterDesktop}
        alt="moonFooter-notfound-img"
        src={lunaDesktop}
      />
    </div>
  </div>
)

export default NotFound
