/* eslint-disable camelcase */
/* eslint-disable react/jsx-max-depth */
import React, { useContext } from 'react'
import styles from './Welcome.module.css'
import content from './Welcome.content'
import welcomeMessage from '../../assets/svg/welcome-image.svg'
import checkIcon from '../../assets/svg/check-icon.svg'
import { PatientContext } from '../../context/PatientContext'

/**
 * Welcome component
 * @returns {Void} Welcome component
 */
const Welcome = () => {
  const { patient } = useContext(PatientContext)
  const { Patient_Name } = patient

  return (
    <div className={styles.WelcomeContainer}>
      <div className={styles.ImageSide}>
        <div className={styles.ImageWelcome}>
          <img
            src={welcomeMessage}
            alt="apollo-message"
          />
        </div>
      </div>
      <div className={styles.MessageSide}>
        <div className={styles.Message}>
          <div className={styles.MessageContent}>
            <div className={styles.MessageIconContainer}>
              <div className={styles.MessageIcon}>
                <img
                  src={checkIcon}
                  alt="check-icon"
                />
              </div>
            </div>
            <div className={styles.MessageText}>
              <p className={styles.MessageTitle}>
                {content.thanks.replace('[Nombre]', Patient_Name)}
              </p>
              <p className={styles.MessageParagraph}>
                {content.msg}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Welcome
