/* eslint-disable camelcase */
import React, { useEffect, useContext } from 'react'
import styles from './HealthMeasures.module.css'
import globalStyles from '../../index.module.css'
import content from './HealthMeasures.content'
import maskImg from '../../assets/png/mask.png'
import { PatientContext } from '../../context/PatientContext'

/**
 * HealthMeasures component
 * @param {Function} handleIsSticky Hanlder to change is sticky state in parent
 * @returns {Void} HealthMeasures component
 */
const HealthMeasures = ({ handleIsSticky }) => {
  const { patient } = useContext(PatientContext)
  const { Country_Ops } = patient
  const measuresBtnRef = React.createRef()

  useEffect(() => {
    const cachedRef = measuresBtnRef.current
    const observer = new IntersectionObserver(
      ([e]) => handleIsSticky(e.isIntersecting),
      { threshold: [content.zero], rootMargin: '0px 0px -68px 0px' }
    )

    observer.observe(cachedRef)

    // unmount
    return () => {
      observer.unobserve(cachedRef)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let healthInfoUrl = content.healthInfo
  switch (Country_Ops) {
  case 'Colombia':
  case 'colombia':
    healthInfoUrl = healthInfoUrl.replace('[domain]', 'co')
    break

  default:
    healthInfoUrl = healthInfoUrl.replace('[domain]', 'mx')
    break
  }

  return (
    <div className={styles.MainContent}>
      <div className={globalStyles.Row}>
        <div className={globalStyles.Column}>
          <img
            src={maskImg}
            alt="mask-health"
            className={styles.MaskImage}
          />
        </div>
        <div className={globalStyles.DoubleColumn}>
          <div className={styles.Title}>
            {content.title}
          </div>
          <div className={styles.Message}>
            {content.message}
          </div>
          <div className={styles.Button}>
            <button
              type="button"
              ref={measuresBtnRef}
              className={styles.MoreBtn}
              onClick={() => {
                measuresBtnRef.current.blur()
                window.open(healthInfoUrl, '_blank')
              }}
            >
              {content.btnMsg}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HealthMeasures
