/* eslint-disable no-magic-numbers */
/* eslint-disable max-statements */
import React, { createRef } from 'react'
import style from './tablet.module.css'
import Utils from '../../../utils/utils'
import Slider from '../Slider/Slider'

/**
 * Tablet Component
 * @return {void}
 */
class Tablet extends React.Component {
  /**
   * Constructor
   * @param {Object} props .
   * @return {void}
   */
  constructor(props) {
    super(props)

    this.curLeft = 0
    this.moveX = 0
    this.startX = 0
    this.curSlide = 0
    this.slideW = 0
    this.offsetLeft = 0
    this.slideMargin = 0
    this.cantSliderShow = window.innerWidth >= 1000 ? 3 : 2
    this.totalSlides = props.info.length

    this.refSlider = createRef()
    this.refSliderTable = createRef()
    this.refImage = createRef()
    this.refAllSlide = Utils.dynamicRef(this.totalSlides)
    this.refDots = createRef()
    this.refWrapper = createRef()

    this.def = {
      transition: {
        speed: 300,
        easing: ''
      },
      swipe: true,
      autoHeight: false,
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount() {
    window.addEventListener('resize', this.updateSliderDimension)
    this.init()
    this.updateSliderDimension()
  }

  /**
   * Component Will Un Mount
   * @return {void}
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSliderDimension)
  }

  init = () => {
    this.setDot()
    this.getSlideW()
  }

  setDot = () => {
    const { hasDots } = this.props
    if (this.totalSlides <= 2 || !hasDots) return
    const { children } = this.refDots.current

    for (let index = 0; index < children.length; index++) {
      if (index === this.curSlide || index - 1 === this.curSlide) {
        children[index].classList.add(style.SliderActive)
        children[index].classList.remove(style.SliderBottom)
      } else {
        children[index].classList.add(style.SliderBottom)
        children[index].classList.remove(style.SliderActive)
      }
    }
    if (this.curSlide === this.totalSlides - 1) {
      children[this.curSlide - 1].classList.add(style.SliderActive)
      children[this.curSlide - 1].classList.remove(style.SliderBottom)
    }
  }

  updateSliderDimension = () => {
    this.cantSliderShow = window.innerWidth >= 1000 ? 3 : 2
    const widthOut = this.cantSliderShow === 2 ? 78 : window.innerWidth * (1 - 0.798)

    this.refSlider.current.style.width = `${((window.innerWidth - widthOut) / this.cantSliderShow) * this.totalSlides}px`
    this.refWrapper.current.style.width = `${window.innerWidth - widthOut}px`

    this.getSlideW()
    this.forceUpdate()
  }

  getSlideW = () => {
    const allSlider = this.refAllSlide
    const node = allSlider[0].current
    if (allSlider.length > 0) {
      this.slideW = parseInt(node.offsetWidth, 10)
      this.offsetLeft = parseInt(node.offsetLeft, 10)
      this.slideMargin = Utils.getStyleItemByProperty(node, 'margin-right')
    } else this.slideW = 0
  }

  getCurrentLeft = () => {
    const { left } = this.refSlider.current.style
    if (left) this.curLeft = parseInt(left, 10)
  }

  /**
   * Go to Slide
   * @return {void}
   */
  gotoSlide = () => {
    this.refSlider.current.style.transition = `left ${this.def.transition.speed / 1000}s ${this.def.transition.easing}`
    if (this.curSlide === 0) {
      this.refSlider.current.style.left = '0px'
    } else {
      this.refSlider.current.style.left = `${-this.curSlide * (this.slideW + 2 * this.slideMargin)}px`
    }

    setTimeout(() => {
      this.refSlider.current.style.transition = ''
    }, this.def.transition.speed)

    this.setDot()
  }

  /**
   * Start Move
   * @param {Object} event .
   * @return {void}
   */
  startMove = (event) => {
    this.getCurrentLeft()
    const touch = event.targetTouches[0] || event.changedTouches[0]
    this.startX = touch.pageX
  }

  /**
   * Moving
   * @param {Object} event .
   * @return {void}
   */
  Moving = (event) => {
    const touch = event.targetTouches[0] || event.changedTouches[0]
    this.moveX = touch.pageX

    this.refSlider.current.style.left = `${this.curLeft + this.moveX - this.startX}px`
  }

  /**
   * End Move
   * @return {void}
   */
  endMove = () => {
    this.getCurrentLeft()

    if (Math.abs(this.moveX - this.startX) === 0 || (this.moveX === 0)) return

    const stayAtCur = !!(Math.abs(this.moveX - this.startX) < 40 || this.moveX === 0)
    const dir = this.startX < this.moveX ? 'left' : 'right'

    if (this.cantSliderShow === this.totalSlides) return
    if (!stayAtCur) {
      dir === 'left' ? this.curSlide -= 1 : this.curSlide += 1
      if (this.curSlide < 0) {
        this.curSlide = 0
      } else if (this.curSlide === this.totalSlides - 1 || (this.curSlide === this.totalSlides)) {
        this.curSlide -= 1
      }
    }
    this.gotoSlide()
    this.restValues()
  }

  restValues = () => {
    this.startX = 0
    this.moveX = 0
  }

  /**
   * Arrow Move
   * @param {String} dir .
   * @return {void}
   */
  arrowMove = (dir) => {
    if (dir === 'prev' && this.curSlide !== 0) this.curSlide -= 1
    if (dir === 'next' && this.curSlide !== this.totalSlides - this.cantSliderShow) this.curSlide += 1

    this.gotoSlide()
  }

  /**
   * Render
   * @return {void}
   */
  render() {
    const { info } = this.props
    const textTitle = 'Ellos ya muestran su sonrisa'
    return (
      <div className={style.TabletContainer}>
        <div className={style.CarouselHeader}>
          <div className={style.HeaderTitle}>{textTitle}</div>
        </div>
        <div className={style.CarouselTablet}>
          {this.totalSlides > this.cantSliderShow
            && (
              <button
                type="button"
                className={[style.Circle, style.Prev].join(' ')}
                onClick={() => { this.arrowMove('prev') }}
              />
            )}
          <div
            ref={this.refWrapper}
            className={style.Wrapper}
          >
            <div
              ref={this.refSlider}
              onTouchStart={(event) => this.startMove(event)}
              onTouchMove={(event) => this.Moving(event)}
              onTouchEnd={(event) => this.endMove(event)}
              className={style.SlideshowContainerTable}
            >
              {info.map((item, index) => (
                <div
                  ref={this.refAllSlide[index]}
                  key={item.id}
                  className={style.PhotoTablet}
                >
                  <Slider item={item} />
                </div>
              ))}
            </div>
          </div>
          {this.totalSlides > this.cantSliderShow
            && (
              <button
                type="button"
                className={[style.Circle, style.Next].join(' ')}
                onClick={() => { this.arrowMove('next') }}
              />
            )}
        </div>
      </div>
    )
  }
}
export default Tablet
