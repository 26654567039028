/* eslint-disable max-params */
/* eslint-disable max-statements */
/**
 * sendRequest to middleware to get data
 * @param {Object} input request body
 * @param {Object} success success action
 * @param {Object} error error action
 * @param {String} responseClass class type for B4A object
 * @returns {Void} send request and receive response
 */
const sendRequest = async (input, success, error, responseClass) => {
  const BasicAuthUsername = 'user'
  const BasicAuthPassword = 'secret'
  const auth = `${BasicAuthUsername}:${BasicAuthPassword}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: authEncoded,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(input)
  }

  try {
    const url = process.env.REACT_APP_MIDDLEWARE_URL

    const response = await fetch(url, requestOptions)
    const data = await response.json()
    const classObjResponse = data[responseClass] || {}

    if (response.ok) {
      success && success(classObjResponse)
    } else {
      error && error(data)
    }
  } catch (err) {
    error && error(err)
  }
}

/**
 *  requestList send request to fetch list of items
 * @param {Object} input request body
 * @param {Object} success success action
 * @param {Object} error error action
 * @returns {Void} send request and receive response
 */
const requestList = async (input, success, error) => {
  const BasicAuthUsername = 'user'
  const BasicAuthPassword = 'secret'
  const auth = `${BasicAuthUsername}:${BasicAuthPassword}`
  const authEncoded = `Basic ${Buffer.from(auth).toString('base64')}`

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: authEncoded,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(input)
  }

  try {
    const url = process.env.REACT_APP_MIDDLEWARE_LIST_URL

    const response = await fetch(url, requestOptions)
    const data = await response.json()
    const classObjResponse = data.items || {}

    if (response.ok) {
      success && success(classObjResponse)
    } else {
      error && error(data)
    }
  } catch (err) {
    error && error(err)
  }
}

export default { sendRequest, requestList }
