export const INITIAL_STEP = 'initial'
export const DATES_STEP = 'dates'
export const ACCEPTS_STEP = 'accepts'
export const CHECKOUT_STEP = 'checkout'
export const SUCCESS_STEP = 'success'
export const TWO = 2
export const ZERO = 0
export const ONE = 1
export const WHATSAPP_COUNTRY_MX = '52 1 '
export const WHATSAPP_COUNTRY_CO = '57 '
