/* eslint-disable react/jsx-no-literals */
/* eslint-disable max-len */
/* eslint-disable no-magic-numbers */
/* eslint-disable no-negated-condition */
/* eslint-disable camelcase */
/* eslint-disable no-alert */
/* eslint-disable no-process-env */
/* eslint-disable react/no-multi-comp */
/* eslint-disable max-statements */

import React, { useContext, useState, useEffect } from 'react'
import {
  Elements,
  useElements,
  useStripe,
  CardNumberElement
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { withRouter } from 'react-router-dom'
import checkImg from '../../assets/png/check.png'
import warningIcon from '../../assets/png/warning-sign.png'
import stripeIcon from '../../assets/png/stripe-icon.png'
import secure100 from '../../assets/png/secure100.png'
import mcafee from '../../assets/png/mcafee.png'
import whatsappIcon from '../../assets/png/whatsapp-icon.png'
import errorAstronaut from '../../assets/png/errorAstronaut.png'
import styles from './Checkout.module.css'
import content from './Checkout.content'
import CheckoutForm from './CheckoutForm'
import { PatientContext } from '../../context/PatientContext'
import LoaderSection from '../LoaderSection/LoaderSection'
import { SUCCESS_STEP } from '../../utils/constants'
import Middleware from '../../Api/Middleware'
import NotFound from '../NotFound/NotFound'
import { BookingContext } from '../../context/BookingContext'
import apiAcuity from '../../Api/Acuity'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

/**
 * @param {Object} props - the props
 * @param {React.Component} props.priceBefore - the span of the price before
 * @param {String} props.price - the price to charge
 * @returns {Void} -.
 */
const CheckoutHeaderText = ({ priceBefore, price }) => (
  <div className={styles.AppointmentText}>
    <h5>{content.appointmentTitle}</h5>
    <p>{content.appointmentText}</p>
    <p>{content.paidText}</p>
    <p style={{ fontSize: '1em', marginBottom: '25px' }}>
      {priceBefore}
      &nbsp;
      {price}
    </p>
  </div>
)

/**
 *
 * @returns {Void} -.
 */
const CheckoutHeaderIcon = () => (
  <div className={styles.AppointmentIcon}>
    <div className={styles.warningImageContainer}>
      <img
        className={styles.warningImage}
        src={warningIcon}
        alt="check"
      />
    </div>
  </div>
)

/**
 * @returns {Void} - CheckoutIva component
 */
const CheckoutIva = () => (
  <p style={{
    fontSize: '12px',
    marginTop: '13px',
    marginBottom: '35px'
  }}
  >
    {content.ivaCopy}
  </p>
)

/**
 *
 * @param {Object} props - the props
 * @param {String} props.text - thte text of the item
 * @returns {Void} -.
 */
const ListItem = ({ text }) => (
  <span>
    <img
      className={styles.checkImage}
      src={checkImg}
      alt="check"
    />
    &nbsp;
    {text}
  </span>
)

/**
 * @returns {Void} - .
 */
const CheckoutList = () => (
  <ul>
    <li>
      <ListItem
        text={content.dentalReviewText}
      />
    </li>
    <li>
      <ListItem
        text={content.diagnosticText}
      />
    </li>
    <li>
      <ListItem
        text={content.scanText}
      />
    </li>
  </ul>
)

/**
 * @returns {Void} -.
 */
const ImageFooter = ({ image, imageName, centerClass }) => {
  let className = styles.ImageFooterContainer
  if (centerClass) {
    className += ` ${styles.Center}`
  }
  return (
    <div className={className}>
      <img
        src={image}
        alt={imageName}
      />
    </div>
  )
}

/**
 * @returns {Void} -.
 */
const FooterIcon = () => (
  <div className={styles.FooterIconContainer}>
    <ImageFooter
      image={stripeIcon}
      imageName="stripe"
    />
    <ImageFooter
      image={mcafee}
      centerClass
      imageName="mcafee"
    />
    <ImageFooter
      image={secure100}
      imageName="secure100"
    />
  </div>
)

/**
 * @returns {Void} -.
 */
const ErrorComponent = () => (
  <div className={styles.ErrorComponent}>
    <div className={styles.ErrorIcon}>
      <div className={styles.ErrorIconContainer}>
        <img
          src={errorAstronaut}
          alt="errorAstronaut"
        />
      </div>
    </div>
    <div className={styles.ErrorText}>
      <h5>{content.errorTextTitle}</h5>
      <p>{content.errorText}</p>
    </div>
  </div>
)

/**
 * @param {Object} props -.
 * @param {String} props.phone -. the phone
 * @returns {Void} -.
 */
const ContactCheckout = ({ phone }) => (
  <div className={styles.ContactCheckoutContainer}>
    <div>
      <h5>{content.contactTitle}</h5>
    </div>
    <div className={styles.WhatsAppcontainer}>
      <div className={styles.whatsappIcon}>
        <img
          src={whatsappIcon}
          alt="whatsapp"
        />
      </div>
      <div className={styles.contactLink}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://api.whatsapp.com/send?phone=${phone}`}
        >
          {content.whastappTitle}
        </a>
      </div>
    </div>
  </div>
)

/**
 *
 * @param {Object} param0 -.
 * @param {String} param0.publicKey -.
 * @param {String} param0.countryOps customer's country
 * @returns {Void} -,
 */
const ErrorAppointment = ({ publicKey, countryOps }) => {
  let urlHost = process.env.REACT_APP_BOOKING_URL
  switch (countryOps) {
  case 'Colombia':
  case 'colombia':
    urlHost = urlHost.replace('[domain]', 'co')
    break

  default:
    urlHost = urlHost.replace('[domain]', 'mx')
    break
  }
  const url = `${urlHost}/${publicKey}`
  return (
    <div className={styles.ErrorAppointmentContainer}>
      <div className={styles.imageErrorAppointment}>
        <img
          src={errorAstronaut}
          alt="errorAstronaut"
        />
      </div>
      <div className={styles.textErrorAppointment}>
        <p>
          Lo sentimos este espacio ya fue reservado, te invitamos a buscar otro espacio
          {' '}
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            aquí
          </a>
        </p>
      </div>
    </div>
  )
}

/**
 * Checkout component
 * @param {Object} data - the props
 * @param {String} data.country - the country of the page
 * @returns {React.Component} Checkout Component
 */
const Checkout = ({ country, nextStep }) => {
  const elements = useElements()
  const stripe = useStripe()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorCard, setErrorCard] = useState(false)
  const [errorCvc, setErrorCvc] = useState(false)
  const [errorDate, setErrorDate] = useState(false)
  const [product, setProduct] = useState(null)
  const [notFound, setNotFound] = useState(null)
  const [errorAppointment, setErrorAppointment] = useState(false)
  const { patient } = useContext(PatientContext)
  const { dateSelected, current, setAppointment } = useContext(BookingContext)

  console.log({
    dateSelected,
    current
  })
  useEffect(() => {
    /**
     * @return {Void} - set the product
     */
    const getProduct = async () => {
      setLoading(true)
      let productKeyName = null
      if (country === 'México') {
        productKeyName = process.env.REACT_APP_PRODUCT_KEY_MX
      }
      if (country === 'Colombia') {
        productKeyName = process.env.REACT_APP_PRODUCT_KEY_CO
      }
      const body = {
        table: 'Product',
        columns: {
          keyName: productKeyName
        }
      }
      await Middleware.sendRequest(body, setProduct, setNotFound, 'Product')
      setLoading(false)
    }
    getProduct()
  }, [country])

  if (notFound) {
    return <NotFound />
  }
  let priceBeforeText = null
  let priceText = null

  if (product) {
    const { price, finalPrice } = product
    if (country === 'México') {
      priceBeforeText = content.priceBeforeMX.replace('{}', price)
      priceText = content.priceTextMX.replace('{}', finalPrice)
    }
    if (country === 'Colombia') {
      priceBeforeText = content.priceBeforeCO.replace('{}', price)
      priceText = content.priceTextCO.replace('{}', finalPrice)
    }
  }

  console.log(product)
  let phone = process.env.REACT_APP_WHATS_MX
  if (country === 'Colombia') {
    phone = process.env.REACT_APP_WHATS_CO
  }
  const priceBefore = <span style={{ textDecoration: 'line-through', fontWeight: '800' }}>{priceBeforeText}</span>
  let errorComponent = null
  let contactComponent = null
  if (error) {
    errorComponent = <ErrorComponent />
    contactComponent = <ContactCheckout phone={phone} />
  }
  let checkoutWrapperClass = styles.CheckoutWrapperContainer
  let loaderClass = styles.ContainerLoader
  if (loading) {
    loaderClass += ` ${styles.Display}`
    checkoutWrapperClass += ` ${styles.noDisplay}`
  } else {
    loaderClass = ` ${styles.noDisplay}`
    checkoutWrapperClass = styles.CheckoutWrapperContainer
  }

  /**
   *
   * @param {Event} event -.
   * @returns {Void} -.
   */
  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    if (!stripe || !elements) {
      return
    }
    if (!patient) {
      alert('Paciente no encontrado')
    }
    const {
      Patient_Name,
      Email,
      Phone
    } = patient
    let countryCode = ''
    if (country === 'México') {
      countryCode = 'MX'
    }
    if (country === 'Colombia') {
      countryCode = 'CO'
    }
    const cardElement = elements.getElement(CardNumberElement)
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: Patient_Name,
        email: Email,
        phone: Phone,
        address: {
          country: countryCode
        }
      },
    })
    console.log(payload)
    if (payload.error) {
      console.log('[error]', payload.error)
      const { code } = payload.error
      switch (code) {
      case 'incomplete_number':
        setErrorCvc(false)
        setErrorDate(false)
        setErrorCard(true)
        break
      case 'incomplete_expiry':
        setErrorCvc(false)
        setErrorDate(true)
        setErrorCard(false)
        break
      case 'invalid_expiry_year_past':
        setErrorCvc(false)
        setErrorDate(true)
        setErrorCard(false)
        break
      case 'incomplete_cvc':
        setErrorCvc(true)
        setErrorDate(false)
        setErrorCard(false)
        break
      default:
        setErrorCard(true)
        setErrorCvc(true)
        setErrorDate(true)
      }
      setLoading(false)
    } else {
      setErrorCard(false)
      setErrorCvc(false)
      setErrorDate(false)
      console.log('[PaymentMethod]', payload.paymentMethod)
      const paymentMethodId = payload.paymentMethod.id
      await paymentMethodHandler(paymentMethodId)
    }
  }

  /**
   * Function to handle the payment with stripe
   * @param {String} paymentMethodId - the id created by stripe
   * @returns {Void} -.
   */
  const paymentMethodHandler = async (paymentMethodId) => {
    let productKeyName = null
    if (country === 'México') {
      productKeyName = process.env.REACT_APP_PRODUCT_KEY_MX
    }
    if (country === 'Colombia') {
      productKeyName = process.env.REACT_APP_PRODUCT_KEY_CO
    }
    const { CustomerId } = patient
    const payload = {
      paymentId: paymentMethodId,
      keyName: productKeyName,
      customerId: CustomerId
    }
    const response = await fetch(process.env.REACT_APP_STRIPE_URL, {
      method: 'POST',
      body: JSON.stringify(payload)
    })
    console.log(response)
    if (response.ok) {
      const data = await response.json()
      console.log(data)
      await makeAppointment()
    } else {
      const data = await response.json()
      console.log(data)
      setError(true)
      setLoading(false)
    }
  }

  /**
   * @return {Void} -.
   */
  const makeAppointment = async () => {
    const {
      Deal_Name,
      Email,
      Phone,
      CustomerId
    } = patient
    const firstName = Deal_Name
    const lastName = ''
    const userData = {
      firstName,
      email: Email,
      phone: Phone,
      lastName
    }
    console.log({ userData })
    const { Appointment_Type_Id } = current
    try {
      const dataAppointment = await apiAcuity.appointments(dateSelected, Appointment_Type_Id, userData, CustomerId)
      console.log(dataAppointment)
      if(dataAppointment && dataAppointment.id){
        window.heyspix("event", "transaction", {"action": "Test-Appointment-Automated"});
      }
      if (dataAppointment.status) {
        setErrorAppointment(true)
        return
      }
      setAppointment(dataAppointment)
      nextStep(SUCCESS_STEP)
    } catch (err) {
      console.log(err)
    }
  }
  const publicKey = patient.PublicKey
  console.log({ publicKey })
  if (errorAppointment) {
    return (
      <div className={checkoutWrapperClass}>
        <ErrorAppointment
          publicKey={publicKey}
          countryOps={country}
        />
      </div>
    )
  }
  return (
    <>
      <div className={loaderClass}>
        <LoaderSection />
      </div>
      <div className={checkoutWrapperClass}>
        <div className={styles.CheckoutContainer}>
          <div className={styles.AppointmentContainer}>
            <CheckoutHeaderIcon />
            <CheckoutHeaderText
              priceBefore={priceBefore}
              price={priceText}
            />
          </div>
          <div className={styles.IvaContainer}>
            <CheckoutIva />
          </div>
          <div className={styles.ListContainer}>
            <CheckoutList />
          </div>
          <div className={styles.CheckoutFormContainer}>
            <CheckoutForm
              errorCard={errorCard}
              errorCvc={errorCvc}
              errorDate={errorDate}
            />
          </div>
          {errorComponent}
          <FooterIcon />
          {contactComponent}
        </div>
        <div className={styles.PayButtonContainer}>
          <button
            className={styles.AnimationContainer}
            type="button"
            onClick={handleSubmit}
            disabled={loading}
          >
            {content.payButtonText}
          </button>
        </div>
      </div>
    </>
  )
}

/**
 *
 * @param {Object} props -.
 * @param {String} props.country -.
 * @returns {React.Component} -. CheckoutWrapper component
 */
const CheckoutWrapper = ({ country, nextStep }) => (
  <Elements stripe={stripePromise}>
    <Checkout
      nextStep={nextStep}
      country={country}
    />
  </Elements>
)
export default withRouter(CheckoutWrapper)
