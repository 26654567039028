/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable multiline-ternary */
/* eslint-disable complexity */
import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import styles from './Accept.module.css'
import messages from './Accept.messages'
import LoaderSection from '../LoaderSection/LoaderSection'
import arrow from '../../assets/svg/arrowBack.svg'
// import clock from '../../assets/svg/clock.svg'
import TimeCounter from '../TimeCounter/TimeCounter'
import TestimonialsCarousel from '../Carousel'
import testimonials from '../../utils/testimonials'
import {
  CHECKOUT_STEP, DATES_STEP, INITIAL_STEP
} from '../../utils/constants'
import CardCenter from '../CardCenter/CardCenter'
import apiCRM from '../../Api/CRM'

/**
 * Accept Component
 * @return {void}
 */
class Accept extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      expiredTime: false,
      loader: false,
      error: false
    }
  }

  backComponent = () => {
    const { nextStep } = this.props
    return (
      <div
        role="button"
        tabIndex={0}
        className={styles.Back}
        onClick={() => nextStep(DATES_STEP)}
      >
        <img
          alt="logo"
          src={arrow}
        />
        <p>{messages.back}</p>
      </div>
    )
  }

  setExpired = () => {
    this.setState({ expiredTime: true })
  }

  sendData = async () => {
    this.setState({ loader: true })
    const {
      nextStep, customerId, dateSelected, branch, dealId
    } = this.props
    const appointment = {
      Smile_Center: branch && branch.Center_Name,
      Appointment_Date: moment(dateSelected).format(),
      Deal_Name: dealId
    }
    await apiCRM.saveAppointment(customerId, appointment)
    this.setState({ loader: false })
    nextStep(CHECKOUT_STEP)
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const { expiredTime, loader, error } = this.state
    const { nextStep, branch, dateSelected } = this.props
    return (
      <div className={styles.ContainerAccept}>
        {this.backComponent()}
        <CardCenter
          center={branch}
          date={dateSelected}
          noShadow
        />
        <TimeCounter setExpired={this.setExpired} />
        {loader
          ? (
            <div className={styles.ContainerLoader}>
              <LoaderSection />
            </div>
          ) : expiredTime ? (
            <>
              <div
                role="button"
                tabIndex={0}
                className={styles.SubmitButton}
                onClick={() => nextStep(INITIAL_STEP)}
              >
                {messages.buttonExpired}
              </div>
            </>
          ) : (
            <>
              <div
                role="button"
                tabIndex={0}
                className={styles.SubmitButton}
                onClick={() => this.sendData()}
              >
                {messages.buttonText}
              </div>
              <p className={styles.Error}>{error}</p>
            </>
          )
        }
        <TestimonialsCarousel info={testimonials} />
      </div>
    )
  }
}

export default Accept
